var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();!_vm.chickenleg
					? handleSubmit(_vm.insertChickenLeg)
					: handleSubmit(_vm.updateChickenLeg)}}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
					var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.chickenLegObject.description),callback:function ($$v) {_vm.$set(_vm.chickenLegObject, "description", $$v)},expression:"chickenLegObject.description"}})],1)]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var errors = ref.errors;
					var valid = ref.valid;
return [_c('b-form-group',{attrs:{"label":"Prioridad","label-for":"priority"}},[_c('b-form-input',{attrs:{"id":"priority","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.chickenLegObject.priority),callback:function ($$v) {_vm.$set(_vm.chickenLegObject, "priority", $$v)},expression:"chickenLegObject.priority"}})],1)]}}],null,true)}),_c('span',{staticClass:"text-info"},[_vm._v(" Prioridades ocupadas: "),_c('br'),_vm._l((_vm.arrayPriorities),function(prioridad,index){return _c('span',{key:prioridad.id},[_c('strong',[_vm._v(_vm._s(prioridad.priority + (index != _vm.arrayPriorities.length - 1 ? ", " : "")))])])})],2)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[(!_vm.busy)?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" "+_vm._s(!_vm.chickenleg ? "Guardar" : "Actualizar")+" ")]):_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }